<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ConfirmBasicSetting :profile-dic="profileDic" />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import getSingleData from '@/firestore/data/get'
import Sidebar from '@/components/student/sidebar/test/Sidebar.vue'
import ConfirmBasicSetting from '@/components/student/basicSetting/ConfirmBasicSetting.vue'

export default {
  components: {
    BRow,
    BCol,
    ConfirmBasicSetting,
    Sidebar,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('profileBasic', ['profileDic']),
  },
  async mounted() {
    const data = {
      collection: 'user',
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      this.$store.dispatch('profileBasic/updateDic', response.data)
    } else {
      window.console.log('error', response.msg)
    }
  },
}
</script>

<style lang="scss">
</style>
